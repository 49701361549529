<template>
  <v-container class="py-7">
    <div class="heading mb-7">Уведомления</div>

    <div v-if="isAuth">
      <div
        v-if="orderNotifications.length || actionNotifications.length"
        class="notifications"
      >
        <NotificationBlock
          v-if="orderNotifications.length"
          type="order"
          :date="formatDate(orderNotifications[0].date_created)"
          :text="orderNotifications[0].push_message"
        />

        <NotificationBlock
          v-if="actionNotifications.length"
          type="action"
          :date="formatDate(actionNotifications[0].date_created)"
          :text="actionNotifications[0].push_message"
        />
      </div>

      <p v-else>Список уведомлений пуст</p>
    </div>

    <p v-else>Авторизуйтесь, чтобы получать уведомления о скидках и акциях</p>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import NotificationBlock from '@/components/NotificationBlock.vue'

export default {
  components: { NotificationBlock },

  data: () => ({
    notifications: null,
  }),

  computed: {
    isAuth() {
      return this.$store.getters['user/isAuthorized']
    },

    orderNotifications() {
      return this.$store.getters['user/orderNotifications']
    },

    actionNotifications() {
      return this.$store.getters['user/actionNotifications']
    },
  },

  methods: {
    formatDate(date) {
      return dayjs(date).format('DD.MM.YYYY')
    },
  },

  async created() {
    try {
      this.$store.commit('overlay/update', { active: true })
      await this.$store.dispatch('user/loadNotifications')
    } catch (error) {
      this.$store.commit('snackbar/update', {
        active: true,
        text: error.message,
      })
    } finally {
      this.$store.commit('overlay/update', { active: false })
    }
  },
}
</script>

<style lang="scss" scoped>
.notifications {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
</style>
