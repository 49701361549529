<template>
  <div class="notification-wrapper" @click="onNotificationClick(type)">
    <div
      v-if="type === 'order'"
      class="icon-wrapper"
      :style="{ padding: '8px' }"
    >
      <BagCheckIcon class="icon" />
    </div>
    <div v-else class="icon-wrapper">
      <CashSaleIcon class="icon" />
    </div>
    <div class="notification-body">
      <div class="notification-title-wrapper">
        <h3>
          {{ type === 'order' ? 'Заказы' : 'Скидки и акции' }}
        </h3>
        <span>{{ date }}</span>
      </div>
      <p class="notification-text">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import BagCheckIcon from '@/components/icons/BagCheckIcon.vue'
import CashSaleIcon from '@/components/icons/CashSaleIcon.vue'

export default {
  components: { BagCheckIcon, CashSaleIcon },
  props: {
    type: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },

  methods: {
    onNotificationClick(type) {
      this.$router.push({
        name: 'NotificationsDetail',
        query: { type: type },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-wrapper {
  display: flex;
  gap: 12px;
}

.icon {
  color: white;
  .theme--dark & {
    color: #121212;
  }
}

.icon-wrapper {
  min-width: 58px;
  height: 58px;
  padding: 4px;
  border-radius: 50%;
  background: var(--v-primary-base);
}

.notification-title-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  h3 {
    font-size: 1rem;
  }
  span {
    font-size: 0.8rem;
    color: var(--v-secondary-base);
  }
}

.notification-body {
  flex-grow: 1;
  overflow: hidden;
  border-bottom: 1px solid #c6c6c6;
  .theme--dark & {
    border-color: var(--v-white-base);
  }
}

.notification-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-size: 0.9rem;
  .theme--dark & {
    color: var(--v-secondary-base);
  }
}
</style>
